@media only screen and (min-width: 390px) {
    .mainmenu{
margin-left: 5px;
    }
.donatebutton{
    margin-right: 5px !important ;
    
}

.logo{ 
    margin-right:60px !important ;
    margin-top:5px !important ;
}

.header{
    margin-top:15px !important ;
}

.ouractivity{
    margin-top :70px !important ;
    border-radius: 25px !important ;
    background-color: #f7eae4 !important ;
   }

   .activitycardsblock{ 
    margin-right: 5px !important ;
    margin-bottom: 50px !important ;
   }

   .donations{
    margin-top :50px !important ;
    margin-right: 5px !important ;
    border-radius: 25px !important ;
    background-color: #e7b5a7 !important ;
    width: 345px !important ;
   }

   .footer{
    margin-top :25px !important ;
    background: rgb(247,234,228);
    background: linear-gradient(360deg, rgba(247,234,228,1) 0%, rgba(231,181,167,1) 35%, rgba(244,170,150,1) 100%);
   }

   .contactlist{
    margin-right: 10px !important;
   }

  .nitzosLogoFooter{
  
  }

  .socialTitleFooter{


  }

  .socialIconsList{
    margin-left:90px !important;
  }

  .about{
    margin-right: 50px !important;
    margin-top: 15px !important;
  }
  .contactform{
    margin-right: 10px !important;
    margin-top: 25px !important;
    width:90% !important ;
    height:1150px !important
  }
  .donation{
    margin-top:25px !important;
  }

  .contactbuttondonationpage{
    margin-right: 110px !important;
    margin-bottom: 20px !important;
  }

  .credit{
    margin-right: 80px !important;
    margin-top: 12px !important
  }

  }



@media only screen and (min-width: 480px) {
    .mainmenu{
      
    }
.donatebutton{
    margin-right: 10px !important ;
    
}

.logo{ 
    margin-right:70px !important ;
    margin-top:5px !important ;
}

.header{
    margin-top:15px !important ;
    margin-right:50px !important ;
}

.ouractivity{
    margin-top :50px !important ;
    border-radius: 25px !important ;
    background-color: #f7eae4 !important ;
    width:430px
   }

   .activitycardsblock{ 
    margin-right:50px !important ;
   }

   .donations{
    margin-top :50px !important ;
    margin-right: 20px !important ;
    border-radius: 25px !important ;
    background-color: #e7b5a7 !important ;
    width: 400px !important ;
   }

   .footer{
    margin-top :25px !important ;
    background: rgb(247,234,228);
    background: linear-gradient(360deg, rgba(247,234,228,1) 0%, rgba(231,181,167,1) 35%, rgba(244,170,150,1) 100%);
   }

   .contactlist{
    margin-right: 10px !important;
   }

  .nitzosLogoFooter{
  
  }

  .socialTitleFooter{


  }

  .socialIconsList{
    margin-left:110px !important;
  }

  .about{
    margin-right: 50px !important;
    margin-top: 15px !important;
  }
  .contactform{
    margin-right: 40px !important;
    margin-top: 25px !important;
    width:80% !important ;
    height:1100px !important
  }

  .donation{
    margin-right: 1px !important;
    margin-top:25px !important;
  }

  .contactbuttondonationpage{
    margin-right: 150px !important;
    margin-bottom: 20px !important;
  }

  .credit{
    margin-right: 120px !important;
    margin-top: 12px !important
  }

  }

  @media only screen and (min-width: 769px) {
    .mainmenu{
      margin-right:50px !important ;
    }
.donatebutton{
    margin-right: 10px !important ;
    
}

.logo{ 
    margin-right:200px !important ;
    margin-top:5px !important ;
}

.header{
    margin-top:15px !important ;
    /* margin-right:50px !important ; */
}
.ouractivity{
    margin-top :70px !important ;
    border-radius: 25px !important ;
    background-color: #f7eae4 !important ;
    width: 700px !important ;
    margin-right: 10px !important ;
   }

   .activitycardsblock{ 
   
   }

   .donations{
    margin-top :50px !important ;
    margin-right: 10px !important ;
    border-radius: 25px !important ;
    background-color: #e7b5a7 !important ;
    width: 700px !important ;
   }

   .footer{
    margin-top :25px !important ;
    background: rgb(247,234,228);
    background: linear-gradient(360deg, rgba(247,234,228,1) 0%, rgba(231,181,167,1) 35%, rgba(244,170,150,1) 100%);
   }

   .contactlist{
    margin-right: 90px !important;
   }

  .nitzosLogoFooter{

  }

  .socialTitleFooter{
  }

  .socialIconsList{
    margin-right:50px !important;
  }

  .about{
    margin-right: 110px !important;
    margin-top: 15px !important;
  }
  .contactform{
    margin-right: 60px !important;
    margin-top: 25px !important;
    width:80% !important ;
    height:1000px !important
  }

  .donation{
    margin-right: 100px !important;
    margin-top:25px !important;
  }

  .contactbuttondonationpage{
    margin-right: 150px !important;
    margin-bottom: 20px !important;
  }

  .credit{
    margin-right: 250px !important;
    margin-top: 12px !important
  }

  }



  @media only screen and (min-width: 900px) {
    .mainmenu{
      margin-right:50px !important ;
      margin-top:115px !important;
    }
.donatebutton{
    margin-right: 10px !important ;
    
}

.logo{ 
    margin-right:30px !important ;
    margin-top:20px !important ;
}

.header{
    margin-top:15px !important ;
    /* margin-right:50px !important ; */
}

.ouractivity{
    margin-top :70px !important ;
    border-radius: 25px !important ;
    background-color: #f7eae4 !important ;
    width: 800px !important;
    margin-right: 20px !important ;
   }

   .activitycardsblock{ 
   
   }
   .donations{
    margin-top :50px !important ;
    margin-right: 20px !important ;
    border-radius: 25px !important ;
    background-color: #e7b5a7 !important ;
    width: 800px !important ;
   }

   .footer{
    margin-top :25px !important ;
    background: rgb(247,234,228);
    background: linear-gradient(360deg, rgba(247,234,228,1) 0%, rgba(231,181,167,1) 35%, rgba(244,170,150,1) 100%);
   }

   .contactlist{
    margin-right: 110px !important;
   }

  .nitzosLogoFooter{
    margin-left: 10px !important;
  }

  .socialTitleFooter{
  }

  .socialIconsList{
    margin-right:70px !important;
  }

  .about{
    margin-right: 120px !important;
    margin-top: 15px !important;
  }

  .contactform{
    margin-right: 80px !important;
    margin-top: 25px !important;
    width:80% !important ;
    height:1000px !important
  }

  .donation{
    margin-right: 10px !important;
    margin-top:25px !important;
  }

  .contactbuttondonationpage{
    margin-right: 150px !important;
    margin-bottom: 20px !important;
  }

  .credit{
    margin-right: 300px !important;
    margin-top: 12px !important
  }

  }

  @media only screen and (min-width: 919px) {
    .mainmenu{
      margin-right:50px !important ;
      margin-top:115px !important;
    }
.donatebutton{
    margin-right: 10px !important ;
    
}

.logo{ 
    margin-right:50px !important ;
    margin-top:1px !important ;
}

.header{
    margin-top:15px !important ;
    /* margin-right:50px !important ; */
}

.ouractivity{
    margin-top :70px !important ;
    border-radius: 25px !important ;
    background-color: #f7eae4 !important ;
    width: 800px !important;
    margin-right: 30px !important ;
   }

   .activitycardsblock{ 
   
   }
   .donations{
    margin-top :50px !important ;
    margin-right: 20px !important ;
    border-radius: 25px !important ;
    background-color: #e7b5a7 !important ;
    width: 810px !important ;
   }
   .footer{
    margin-top :25px !important ;
    background: rgb(247,234,228);
    background: linear-gradient(360deg, rgba(247,234,228,1) 0%, rgba(231,181,167,1) 35%, rgba(244,170,150,1) 100%);
   }

   .contactlist{
    margin-right: 120px !important;
   }

  .nitzosLogoFooter{
    margin-left: 10px !important;
  }

  .socialTitleFooter{
  }

  .socialIconsList{
    margin-right:75px !important;
  }

  .about{
    margin-right: 120px !important;
    margin-top: 15px !important;
  }
  .contactform{
    margin-right: 80px !important;
    margin-top: 25px !important;
    width:80% !important ;
    height:1000px !important
  }

  .donation{
    margin-right: 20px !important;
    margin-top:25px !important;
  }

  .contactbuttondonationpage{
    margin-right: 150px !important;
    margin-bottom: 20px !important;
  }

  .credit{
    margin-right: 300px !important;
    margin-top: 12px !important
  }
  }


  @media only screen and (min-width: 1200px) {
 .mainmenu{
      margin-right:50px !important ;
      margin-top:115px !important;
    }
.donatebutton{
    margin-right: 10px !important ;
    
}

.logo{ 
    margin-right:100px !important ;
    margin-top:1px !important ;
}

.header{
    margin-top:10px !important ;
    /* margin-right:50px !important ; */
}

.ouractivity{
    margin-top :70px !important ;
    border-radius: 25px !important ;
    background-color: #f7eae4 !important ;
    width: 1100px !important;
    margin-right: 25px !important ;
   }

   .activitycardsblock{ 
   
   }

   .donations{
    margin-top :50px !important ;
    margin-right: 20px !important ;
    border-radius: 25px !important ;
    background-color: #e7b5a7 !important ;
    width: 1105px !important ;
   }

   .footer{
    margin-top :25px !important ;
    background: rgb(247,234,228);
    background: linear-gradient(360deg, rgba(247,234,228,1) 0%, rgba(231,181,167,1) 35%, rgba(244,170,150,1) 100%);
   }

   .contactlist{
    margin-right: 180px !important;
   }

  .nitzosLogoFooter{
    margin-left: 100px !important;
  }

  .socialTitleFooter{
     margin-left: 100px !important;
  }

  .socialIconsList{
    margin-right:75px !important;
  }

  .about{
    margin-right: 120px !important;
    margin-top: 15px !important;
  }
  .contactform{
    margin-right: 250px !important;
    margin-top: 25px !important;
    width:50% !important ;
    height:1000px !important
  }

  .donation{
    margin-right: 100px !important;
    margin-top:25px !important;
  }

  .contactbuttondonationpage{
    margin-right: 150px !important;
    margin-bottom: 20px !important;
  }

  .credit{
    margin-right: 470px !important;
    margin-top: 30px !important
  }

  }

  
  @media only screen and (min-width: 1919px) {
    .mainmenu{
         margin-right:100px !important ;
         margin-top:115px !important;
       }
   .donatebutton{
       margin-right: 10px !important ;
       
   }
   
   .logo{ 
       margin-right:150px !important ;
       margin-top:1px !important ;
   }
   
   .header{
       margin-top:10px !important ;
       margin-right:50px !important ;  
   }
   .ouractivity{
    margin-top :70px !important ;
    border-radius: 25px !important ;
    background-color: #f7eae4 !important ;
   }

   .activitycardsblock{ 
    margin-right: 100px !important ;
    margin-bottom: 50px !important;
   }

   .donations{
    margin-top :20px !important ;
    margin-right: 20px !important ;
    border-radius: 25px !important ;
    background-color: #e7b5a7 !important ;
    width: 1105px !important ;
   }

   .footer{
    margin-top :25px !important ;
    background: rgb(247,234,228);
    background: linear-gradient(360deg, rgba(247,234,228,1) 0%, rgba(231,181,167,1) 35%, rgba(244,170,150,1) 100%);
   }

   .contactlist{
    margin-right: 180px !important;
   }

  .nitzosLogoFooter{
    margin-left: 100px !important;
  }

  .socialTitleFooter{
margin-left: 100px !important;

  }
  .socialIconsList{
    margin-right:75px !important;
  }

  .about{
    margin-right: 100px !important;
    margin-top: 15px !important;
  }

  .donation{
    margin-right: 40px !important;
    margin-top: 15px !important;
  }

  .contactform{
    margin-right: 250px !important;
    margin-top: 25px !important;
    width:50% !important ;
    height:1000px !important
  }

  .contactbuttondonationpage{
    margin-right: 150px !important;
    margin-bottom: 30px !important;
  }

  .donation{

  }

  .credit{
    margin-right: 470px !important;
    margin-top: 12px !important
  }
 }


